import { StyleSheet } from 'aphrodite';
import COLORS from '../../../styles/colors';

const styles = StyleSheet.create({
  root: {
    width: '100%',
    padding: 16,
    minHeight: '100vh'
  },
  content: {
    maxWidth: 1200,
    margin: '0 auto'
  },
  contentBox: {
    padding: 32,
    boxShadow: '0 8px 40px rgba(0,0,0,0.08)',
    borderRadius: 12,
    maxWidth: 600,
    margin: '32px auto'
  },
  actionsContainer: {
    padding: 16
  },
  finishAndWipe: {
    maxWidth: 664,
    margin: '0 auto',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 14,
    color: COLORS.lightBlack,
    padding: 32,
    ':hover': {
      cursor: 'pointer'
    }
  }
});

export default styles;
