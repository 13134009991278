import React from 'react';
import Layout from '../containers/gatsby/layout';
import BookingCompleteContainer from '../containers/bookingStep5/BookingSuccess/BookingSuccessContainer';

const SuccessfulBookingPage = (props) => (
  <Layout hideHeader={true} hideFooter={true} location={props.location}>
    <BookingCompleteContainer/>
  </Layout>
);

export default SuccessfulBookingPage;

