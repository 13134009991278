import React, { Component } from 'react';
import BookingSuccess from './BookingSuccess';
import { connect } from 'react-redux';
import { resetBooking, wipeData } from './BookingSuccessActions';
import { navigate } from 'gatsby';
import BookingHeader from '../../../components/booking/BookingHeader/BookingHeader';

class BookingSuccessContainer extends Component {
  componentDidMount() {
    this.props.resetBooking();
  }

  finish = () => {
    navigate('/');
    // if (!this.props.auth.hasEmailAndPassword) {
    //   this.props.openCreatePasswordModal();
    // } else {
    //   navigate('/');
    // }
  };

  wipeData = () => {
    this.props.wipeData();
    navigate('/book');
  };

  render() {
    return (
      <>
        <BookingHeader hideQuoteTimeout={true} title={'Success'} showCheck={true} goNext={this.finish} buttonText={'Return home'} hidePrice={true}/>
        <BookingSuccess
          {...this.props}
          finish={this.finish}
          wipeData={this.wipeData}
        />
      </>
    )
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    userDetails: state.userDetails
  }
}

const mapDispatchToProps =  {
  resetBooking: resetBooking,
  wipeData: wipeData
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingSuccessContainer);